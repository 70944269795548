import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ loading }) => {
    return (
        loading ? (
            <div className="spinner-overlay">
                <div className="spinner"></div>
            </div>
        ) : null
    );
}

export default LoadingSpinner;